import React from 'react'
import Home from './components/Home'
import About from './components/About'

function App() {
  return (
    <div>
      <Home />
      <About />
    </div>
  )
}

export default App