import React, { useState } from 'react';
import Modal from './Modal';
import kea from '../assets/kea.png'
import oracle from '../assets/eCertificate.png'
import python from '../assets/python_basic certificate.png'
import reactCert from '../assets/python_basic certificate.png'



function About() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModalWithContent = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };
  const projects = (
    <div className='p-4 gap-4'>
      <h2 className="text-2xl font-bold mb-4 text-center">Projects</h2>
      <div className='grid md:grid-cols-4 m-4 justify-center'>
        <div className=' w-[180px] p-4 justify-center'>
          <a href='https://kea-hydraulic.com/'>
            <img className=' mx-auto my-4 rounded-lg' src={kea} alt='/' />
          </a>
          <p className=' text-center'>KEA Hydraulic</p>
        </div>
      </div>
    </div>
  );
  const certificates = (
    <div className='p-4 gap-4'>
      <h2 className="text-2xl font-bold mb-4 text-center">Certificates</h2>
      <div className='grid md:grid-cols-4 m-4 justify-center'>
        <div className=' w-[180px] p-4 justify-center'>
            <img className=' mx-auto my-4 rounded-lg hover:scale-[250%] md:hover:scale-[300%]' src={oracle} alt='/' />
        </div>
        <div className=' w-[180px]  p-4 justify-center'>
            <img className=' mx-auto my-4 rounded-lg hover:scale-[250%] md:hover:scale-[300%]' src={python} alt='/' />
        </div>
        <div className=' w-[180px]  p-4 justify-center'>
            <img className=' mx-auto my-4 rounded-lg hover:scale-[250%] md:hover:scale-[300%]' src={reactCert} alt='/' />
        </div>
      </div>
    </div>
  );

  return (
    <div className='w-full px-16 py-8'>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} content={modalContent} />
      <h1 className='font-bold text-center text-3xl'>About me</h1>
      <div className='max-w-[950px] mx-auto grid md:grid-cols-2 p-4'>
        <div className=' mx-auto flex flex-col items-center'>
          <h1 className=' p-4 text-xl font-bold'>
            Work
          </h1>
          <button className='bg-transparent text-base p-4' onClick={() => handleOpenModalWithContent(projects)}>Projects</button>
          <a href='/Artem_CV.pdf' download className=' text-base p-4'>
            CV
          </a>
          <button className='bg-transparent text-base p-4' onClick={() => handleOpenModalWithContent(certificates)}>Certificates</button>
        </div>
        <div className=' mx-auto flex flex-col items-center'>
          <h1 className=' p-4 text-xl font-bold'>
            Contact
          </h1>
          <a href='https://github.com/Tyo0010' className=' text-base p-4'>Github</a>
          <a href='https://www.linkedin.com/in/artem-pak-5ba8aa200/' className=' text-base p-4'>Linkedin</a>
          <a href='https://www.instagram.com/tyomapak/' className=' text-base p-4'>Instagram</a>
        </div>
      </div>
    </div>
  );
}

export default About;
