import React from 'react'
import { ReactTyped } from 'react-typed'

function Home() {
    return (
        <div className={`w-full px-4 bg-[url('/src/assets/bg.webp')] bg-no-repeat`}>
            <div className=' max-w-[1240px] w-full h-screen mx-auto text-left justify-center items-center flex flex-col'>
                <div className='flex flex-col justify-center w-full md:w-[50%] items-center mx-auto my-4 gap-2 bg-[#F5FBEF] rounded-2xl p-4'>
                    <ReactTyped className=' text-2xl md:text-4xl sm:text-3xl font-bold text-left' strings={["Hello, I'm Artem Pak"]} typeSpeed={100} />
                    <h1 className='  md:text-base sm:text-sm text-xs text-[#DE6B48]'>Software Developer | Cybersecurity Enthusiast</h1>
                    <p>
                        I'm a software engineer with a keen interest in Frontend Development.
                        I've been working in the industry for over 1 years now,
                        honing my skills in this area.
                    </p>

                    <button className=' md:w-[200px] w-[150px] md:text-base rounded-md font-medium my-6 mx-auto py-3 md:mx-0 bg-[#92AD94] hover:bg-[#DE6B48]'>
                        <a href='https://cal.com/tyopak'>
                            Contact me
                        </a>
                    </button>
                </div>
            </div>

        </div>
    )
}

export default Home