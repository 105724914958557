import React from 'react'

function Modal({ isOpen, onClose, content }) {


  return (
    <div
      onClick={onClose}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${isOpen ? "visible ☐ bg-black/20" : "invisible"}`}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-[#F5FBEF] text-[#03254E] max-w-[60%] rounded-xl p-6 transition-all ${isOpen ? 'scale-100 opacity-100' : 'scale-125 opacity-0'}`}>
        <button
          onClick={onClose}
          className='absolute top-2 w-10 h-10 right-2 p-1 rounded-lg bg-transparent hover:scale-125'>
          x
        </button>
        {content}
      </div>
    </div>
  );
}

export default Modal